import { useFilialStore } from '~/stores/filial'
import { Token } from '~/types/utils'

type TiCartProduct = {
  id: number
  cartAmount: number
}

type DeliveryInfo = {
  can_delivery: boolean
  filial_id: number
  coords: {
    latitude: number
    longitude: number
  }
  price: number
  info: {
    terminalGroupId: string
    organizationId: string
    deliveryDurationInMinutes: number
    zone: string
    deliveryServiceProductId: string
  }
}

export const useCartStore = defineStore({
  id: 'cart',
  state: () => ({
    products: [] as Product[],
    recommendations: [],
    productsQuantity: 0,
    unauthorizedToken: useCookie(Token.UNAUTHORIZED).value || null,
    amount: 0,
    cashAmount: undefined,
    addLoading: false,
    cartId: null as number | null,
    isDelivery: true,
    isExpressDelivery: false,
    hasPickupOnlyProducts: false,
    assertDeliveryMinAmount: null,
    selectedPickupDate: null as string | null,
    deliveryTimes: [],
    pickupTimes: [],
    pickupDates: [],
    deliveryDates: [],
    timeIntervals: {},
    canDelivery: true,
    geoJson: {},
    selectedExpressDeliveryDate: null,
    selectedDeliveryDate: null,
    allProductsPickupOnly: null as boolean | null,
    cartProductsIdList: [] as TiCartProduct['id'][],
    cartProducts: [] as TiCartProduct[],
    cartProductList: [],
    minAmountForDelivery: 3000,
    maxPostPaymentAmount: 15000,
    yaDeliveryStopHour: 0,
    loading: false,
    yaDeliveryStartHour: 0,
    deliveryPrice: 0,
    selectedDeliveryTime: null,
    selectedExpressDeliveryTime: null,
    selectedPickupTime: null as string | null,
    deliveryAddress: {
      country: null,
      city: null,
      street: null,
      house: null,
      flat: null,
      entrance: null,
      door_phone: null,
      floor: null,
    },
    deliveryCoords: {
      latitude: null as number | null,
      longitude: null as number | null,
    },
  }),
  getters: {
    UNAUTHORIZED_TOKEN(state) {
      return state.unauthorizedToken
        ? state.unauthorizedToken
        : useCookie(Token.UNAUTHORIZED).value
    },
    PICKUP_TIMES(state) {
      return state.pickupTimes.map((time) => ({ value: time, time }))
    },
    PICKUP_DATES(state) {
      return state.pickupDates.map((date) => ({ value: date, date }))
    },
    DELIVERY_TIMES(state) {
      return state.deliveryTimes.map((time) => ({ value: time, time }))
    },
    DELIVERY_DATES(state) {
      if (state.deliveryDates.length)
        return state.deliveryDates
          .slice(0, 7)
          .map((date) => ({ value: date, date }))
      return state.deliveryDates.map((date) => ({ value: date, date }))
    },
    SELECTED_DATE(state) {
      if (this.isExpressDelivery) {
        return state.selectedExpressDeliveryDate
      } else {
        return state.isDelivery
          ? state.selectedDeliveryDate
          : state.selectedPickupDate
      }
    },
    ORDER_TYPE(state) {
      return state.isDelivery ? 'delivery' : 'pickup'
    },
    SELECTED_TIME(state) {
      if (state.isExpressDelivery) {
        return state.selectedExpressDeliveryTime
      } else {
        return state.isDelivery
          ? state.selectedDeliveryTime
          : state.selectedPickupTime
      }
    },
    IS_PRODUCT_IN_CART(state) {
      return (id: number) => {
        return state.cartProductsIdList.includes(id)
      }
    },
    CART_PRODUCTS_LISTS_IDS(state) {
      if (state.cartProducts.length > 0) {
        return state.cartProducts.map((el) => el.id)
      }
      return []
    },
    CART_AMOUNT(state) {
      if (state.cartProducts.length > 0) {
        const res = state.cartProducts.map((el) => el.cartAmount)
        return res.reduce((acc, cur) => acc + cur, 0)
      } else {
        return 0
      }
    },
    HAS_PICKUP_ONLY_PRODUCTS(state) {
      return state.products.every((product) => product.is_special)
    },
  },
  actions: {
    SET_DELIVERY_COORDS(coords: [number, number]) {
      this.deliveryCoords.latitude = coords[0]
      this.deliveryCoords.longitude = coords[1]
    },
    setProductToBasket(data: any, quantity = 1) {
      let product = null
      let combos = null
      if (data?.product) {
        product = data?.product
        combos = data?.combos
      } else {
        product = data
      }
      const params = {
        token:
          useCookie(Token.UNAUTHORIZED).value &&
          !useCookie(Token.ACCESS_TOKEN).value
            ? useCookie(Token.UNAUTHORIZED).value
            : undefined,
        products: [
          {
            id: product?.id,
            quantity,
            inscription: product?.inscription,
            combos,
          },
        ],
      }
      return new Promise((resolve, reject) => {
        useApi()
          .$post<IProducts>('/cart/product', { body: params })
          .then((response) => {
            this.setCart(response)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchProductsToBasket() {
      this.setLoading(true)
      const unauthorizedToken = useCookie(Token.UNAUTHORIZED).value
      const accessToken = useCookie(Token.ACCESS_TOKEN).value

      const params = accessToken ? {} : { token: unauthorizedToken }

      return new Promise((resolve, reject) => {
        useApi()
          .$get<any>('/cart', { params })
          .then((response) => {
            this.setCart(response)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => this.setLoading(false))
      })
    },
    minusProduct(id: number) {
      const params = {
        id,
        token:
          useCookie(Token.UNAUTHORIZED).value &&
          !useCookie(Token.ACCESS_TOKEN).value
            ? useCookie(Token.UNAUTHORIZED).value
            : undefined,
      }
      useApi()
        .$post<IProducts>('/cart/product/decrement', {
          body: { ...params },
        })
        .then((res) => this.setCart(res))
    },
    removeProduct(hash: string) {
      const params = {
        hash,
        token:
          useCookie(Token.UNAUTHORIZED).value &&
          !useCookie(Token.ACCESS_TOKEN).value
            ? useCookie(Token.UNAUTHORIZED).value
            : undefined,
      }
      return new Promise((resolve, reject) => {
        useApi()
          .$delete<IProducts>('cart/product', { params })
          .then((response) => {
            this.setCart(response)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    setDeliveryFlat(flat: string) {
      this.deliveryAddress.flat = flat
    },
    setDeliveryEntrance(entrance: string) {
      this.deliveryAddress.entrance = entrance
    },
    setDeliveryFloor(floor: string) {
      this.deliveryAddress.floor = floor
    },
    setSelectedPickupDate(selectedPickupDate: string) {
      this.selectedPickupDate = selectedPickupDate
      this.pickupTimes = this.timeIntervals.pickup[this.selectedPickupDate]
      if (!this.pickupTimes.includes(this.selectedPickupTime)) {
        this.selectedPickupTime = this.pickupTimes[0]
      }
    },
    setSelectedPickupTime(selectedPickupTime: string) {
      this.selectedPickupTime = selectedPickupTime
    },
    setDeliveryAddress(obj) {
      this.deliveryAddress.country = obj.getCountry()
      this.deliveryAddress.city = obj.getLocalities()
        ? obj.getLocalities()[0]
        : null
      this.deliveryAddress.street = obj.getThoroughfare()
      this.deliveryAddress.house = obj.getPremiseNumber()
    },
    setDeliveryDoorPhone(doorphone: string) {
      this.deliveryAddress.door_phone = doorphone
    },
    checkProductsForPickupOnly() {
      this.hasPickupOnlyProducts = this.products.some((product) =>
        Boolean(product.pickup_only)
      )
      this.allProductsPickupOnly = this.products.every((product) =>
        Boolean(product.is_special)
      )
    },
    setMaxPostPaymentAmount(data: number) {
      this.maxPostPaymentAmount = data
    },
    setYaDeliveryStopHour(data: string) {
      this.yaDeliveryStopHour = parseInt(data)
    },
    setYaDeliveryStartHour(data: string) {
      this.yaDeliveryStartHour = parseInt(data)
    },
    setMinAmountForDelivery(data: number) {
      this.minAmountForDelivery = data
    },

    resetDeliveryState() {
      this.deliveryPrice = 0
      this.deliveryAddress = {
        country: null,
        city: null,
        street: null,
        house: null,
        flat: null,
        entrance: null,
        door_phone: null,
        floor: null,
      }
      this.deliveryCoords = {
        latitude: null,
        longitude: null,
      }
    },
    getDeliveryZone() {
      const city = useCityCookie().get()
      const params = {
        name: 'delivery_zones',
        city_id: city ? city.id : null,
      }
      return new Promise((resolve, reject) => {
        this.setLoading(true)
        useApi()
          .$get('file', { params })
          .then((response) => {
            this.geoJson = JSON.parse(response)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    setSelectedDeliveryDate(selectedDeliveryDate) {
      this.selectedDeliveryDate = selectedDeliveryDate
      if (!this.isExpressDelivery) {
        this.deliveryTimes =
          this.timeIntervals?.delivery[this.selectedDeliveryDate]
        if (!this.deliveryTimes.includes(this.selectedDeliveryTime)) {
          this.selectedDeliveryTime = this.deliveryTimes[0]
        }
      }
    },
    setIsDelivery(isDelivery: boolean) {
      this.isDelivery = isDelivery
    },
    SET_DELIVERY(delivery: DeliveryInfo) {
      this.deliveryPrice = delivery.price
      this.canDelivery = delivery.can_delivery
    },
    SET_SELECTED_EXPRESS_DELIVERY_DATE(selectedExpressDeliveryDate) {
      this.selectedExpressDeliveryDate = selectedExpressDeliveryDate
    },
    SET_SELECTED_EXPRESS_DELIVERY_TIME(selectedExpressDeliveryTime) {
      this.selectedExpressDeliveryTime = selectedExpressDeliveryTime
    },
    getDeliveryInfo() {
      if (!this.deliveryCoords.latitude) {
        return
      }
      const filialStore = useFilialStore()
      const city = useCityCookie().get()

      const params = {
        ...this.deliveryCoords,
        is_express: this.isExpressDelivery ? 1 : 0,
        city_id: city.id,
      }

      this.setLoading(true)
      useApi()
        .$get<DeliveryInfo>(`cart/${this.cartId}/delivery`, { params })
        .then((response) => {
          this.SET_DELIVERY(response)
          if (response.filial_id) {
            filialStore.setActiveFilialById(response.filial_id)

            if (this.isExpressDelivery) {
              const currentDate = new Date()
                .toLocaleDateString('en-GB')
                .replaceAll('/', '.')
              this.SET_SELECTED_EXPRESS_DELIVERY_DATE(currentDate)
              this.SET_SELECTED_EXPRESS_DELIVERY_TIME(
                Math.round(response.yandex.eta).toString() + ' мин'
              )
            }
          }
        })
        .finally(() => {
          this.setLoading(false)
        })
    },
    resetCart() {
      this.amount = 0
      this.products = []
      this.productsQuantity = 0
      this.cartId = null
      this.recommendations = []
    },
    setCartId(cartId: number) {
      this.cartId = cartId
    },
    removeCart() {
      const params: { token?: string } = {}
      if (this.UNAUTHORIZED_TOKEN) {
        params.token = this.UNAUTHORIZED_TOKEN
      }
      return useApi()
        .$delete('cart', { params })
        .then(() => {
          this.resetCart()
          this.resetToken()
          // context.commit('RESET_GREETING_CARDS');
        })
    },
    resetToken() {
      this.unauthorizedToken = null
      localStorage.removeItem('id_token')
    },
    setIsExpressDelivery(isExpress: boolean) {
      this.isExpressDelivery = isExpress
    },
    setSelectedDeliveryTime(selectedDeliveryTime) {
      this.selectedDeliveryTime = selectedDeliveryTime
    },
    decreaseAmountOfProduct() {
      const reqProduct = this.cartProducts.find((el) => el.id === id)
      if (!reqProduct) return

      if (reqProduct.cartAmount > 2) {
        reqProduct.cartAmount--
      } else {
        this.cartProducts = this.cartProducts.filter((el) => el.id !== id)
      }
    },
    setPeriods(response) {
      const intervals = response.intervals
      this.timeIntervals = intervals
      if (intervals.delivery) {
        this.deliveryDates = Object.keys(intervals?.delivery)
      }
      if (intervals.pickup) {
        this.pickupDates = Object.keys(intervals.pickup)
      }
      if (!this.selectedDeliveryDate) {
        const currentDate = new Date()
          .toLocaleDateString('en-GB')
          .replaceAll('/', '.')
        const currentTime = new Date().getHours()
        if (this.deliveryDates[0] === currentDate && currentTime > 15) {
          this.selectedDeliveryDate = this.deliveryDates[1]
        } else {
          this.selectedDeliveryDate = this.deliveryDates[0]
        }
      }
      if (!this.selectedPickupDate) {
        this.selectedPickupDate = this.pickupDates[0]
      }
      if (intervals.pickup) {
        this.pickupTimes = intervals.pickup[this.selectedPickupDate]
      }
      if (intervals.delivery) {
        this.deliveryTimes = intervals.delivery[this.selectedDeliveryDate]
      }
      if (!this.selectedPickupTime) {
        this.selectedPickupTime = this.pickupTimes[0]
      }
      if (!this.selectedDeliveryTime) {
        this.selectedDeliveryTime = this.deliveryTimes[0]
      }
    },
    setCart(res: any) {
      if (res.token && !this.UNAUTHORIZED_TOKEN) {
        useCookie(Token.UNAUTHORIZED).value = res.token
      }
      this.products = res.products
      this.recommendations = res.recommendations
      this.amount = res.amount
      this.cartId = res.id
      this.productsQuantity = res.quantity_products
      this.loading = false
      this.addLoading = false
    },
    setLoading(loading: boolean) {
      this.loading = loading
    },
  },
})
