import { useCartStore } from '~/stores/cart'

type FilialsResponse = {
  filials: IFilial[]
  max_post_payment_amount: number
  min_amount_for_delivery: number
  what_time_stop_accept_orders: string
  ya_delivery_start_hour: string
  ya_delivery_stop_hour: string
}

interface State {
  filials: IFilial[]
  activeFilial: IFilial | {}
  showFilialModal: boolean
}

export const useFilialStore = defineStore({
  id: 'filial',
  state: (): State => ({
    filials: [],
    activeFilial: {},
    showFilialModal: false,
  }),
  actions: {
    setFilials(filials: IFilial[]) {
      this.filials = filials
    },
    setActiveFilial(filial: IFilial | {}) {
      this.activeFilial = filial
    },
    setActiveFilialById(filialId: IFilial['id']) {
      const filials = this.filials
      this.activeFilial = filials.filter((filial) => {
        return filial.id === filialId
      })[0]
    },
    showFilialModal(name) {
      this.showFilialModal = name
    },
    getFilials() {
      const cartStore = useCartStore()
      const city = useCityCookie().get()
      if (city) {
        return useApi()
          .$get<FilialsResponse>('/filial', { params: { city_id: city.id } })
          .then((res) => {
            this.setFilials(res.filials)
            cartStore.setMaxPostPaymentAmount(res.max_post_payment_amount)
            cartStore.setYaDeliveryStartHour(res.ya_delivery_start_hour)
            cartStore.setYaDeliveryStopHour(res.ya_delivery_stop_hour)
            if (res.min_amount_for_delivery > 0) {
              cartStore.setMinAmountForDelivery(res.min_amount_for_delivery)
            }
          })
      }
    },
  },
})
